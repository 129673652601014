import React, { useEffect } from "react";
import { HashRouter as Router, Navigate, Outlet, Route, Routes, useLocation } from "react-router-dom";
import TopMenuStatus from "Stores/TopMenuStatus";
import Version, { IConfig } from "Stores/Version";
import ThemeMode from "../Stores/ThemeMode";
import WrongChain from './Materials/WrongChain';
import ComingSoon from "./Pages/ComingSoon";
import Home from "./Pages/Home";

type IProps = {};

type IState = {
	versionStore: IConfig | null;
};

export default class Main extends React.Component<IProps, IState> {
	private removeThemeModeOnSwitch = () => { };
	private removeVersionOnSwitch = () => { };


	public constructor(props: IProps) {
		super(props);
		this.state = {
			versionStore: Version.getInstance().version
		};
		this.updateThemeMode();
		this.updateVersion();
	}

	public render(): JSX.Element {
		return (
			<Router basename="/">
				<WrongChain/>
				<Routes>
					<Route element={<BindRouter version={this.state.versionStore} />}>
						<Route element={<Home />} path="/" />
						<Route element={<Navigate to="/" replace />} path="*" />
					</Route>
				</Routes>
			</Router>
		);
	}

	public componentDidMount() {
		this.removeThemeModeOnSwitch = ThemeMode.getInstance().onSwitch(() => this.updateThemeMode());
		this.removeVersionOnSwitch = Version.getInstance().onSwitch(() => this.updateVersion())
	}

	public componentWillUnmount() {
		this.removeThemeModeOnSwitch();
		this.removeVersionOnSwitch();
	}

	private updateThemeMode() {
		document.body.setAttribute("theme-mode", ThemeMode.getInstance().type);
	}

	private updateVersion() {
		this.setState({
			versionStore: Version.getInstance().version
		});
		document.body.setAttribute("version", Version.getInstance().version?.version ?? "");
	}
}

function BindRouter({ version }: { version: IConfig | null }) {
	const location = useLocation();

	useEffect(() => {
		window.scrollTo(0, 0);
		TopMenuStatus.getInstance().close();
		document.body.setAttribute("route", location.pathname);
	}, [location.pathname]);

	if (version && version.pagesComingSoon) {
		const pageComingSoon = version?.pagesComingSoon[location.pathname] 
		if (pageComingSoon?.enabled) {
			return <ComingSoon title={pageComingSoon.title} text={pageComingSoon.text} />
		}
	}
	return <Outlet />;
}
