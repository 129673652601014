import React from "react";
import { Link } from "react-router-dom";
import ConnectWallet from "../ConnectWallet";
import logo from "../../../assets/images/pages/home/logo.png";
import classes from "./classes.module.scss";

type IState = {};
type IProps = {};
export default class Header extends React.Component<IProps, IState> {
	private onScrollYDirectionChange = () => {};

	public render(): JSX.Element {
		return (
			<header className={classes["root"]}>
				<Link className={classes["left"]} to="/">
					<img alt="logo" src={logo} className={classes["logo"]} />
				</Link>
				<div />
				<div className={classes["right"]}>
					<ConnectWallet />
				</div>
			</header>
		);
	}

	public componentDidMount() {}

	public componentWillUnmount() {
		this.onScrollYDirectionChange();
	}
}

