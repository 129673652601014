import { ethers } from "ethers";

import Config from "Configs";
import NftCollectionContract from "Services/Contracts/Classes/NftCollectionContract";
import Services from "Services/Contracts/Services";
import EventService from "Services/EventEmitter";
import EthBigNumber from "Services/Wallet/EthBigNumber";
import Wallet, { IWallet } from "Stores/Wallet";

import NftCollectionAbi from "../assets/abi/NftCollectionStandard.json";
import * as Webservices from "../Services/Webservices";

export type ITotalTokens = {
	lockedTokens: EthBigNumber;
	availableTokens: EthBigNumber;
};
export interface IContract {
	nftCollectionContract: NftCollectionContract | null;
}

class EventEmitter extends EventService {}

export default class Contract {
	private static ctx: Contract;
	private nftCollectionContract: NftCollectionContract | null = null;
	private readonly event = new EventEmitter();

	public get contractData(): IContract {
		return {
			nftCollectionContract: this.nftCollectionContract,
		};
	}

	private constructor() {
		this.setContractData(Wallet.getInstance().walletData);
		Contract.ctx = this;
		Wallet.getInstance().onChange(async (web3Event: IWallet) => await this.setContractData(web3Event));
	}

	public static getInstance() {
		if (!Contract.ctx) new this();
		return Contract.ctx;
	}

	public getNftCollectionContract() {
		return this.nftCollectionContract;
	}

	public onChange(callback: (contractData: IContract) => void) {
		this.event.on("contract-change", callback);
		return () => {
			this.event.off("contract-change", callback);
		};
	}

	private async setContractData(walletData: IWallet) {
		const config = Config.getInstance().get()
		const address = config.contracts.nftCollection.address;
		const abi = NftCollectionAbi.abi;
		const provider =
			walletData.provider ??
			new ethers.providers.InfuraProvider(config.wallet.network, config.wallet.infuraId);
		const signer = walletData.provider?.getSigner() ?? null;
		const contract = new NftCollectionContract(address, abi, provider, signer);

		this.nftCollectionContract = contract;
		this.event.emit("contract-change", this.contractData);
	}

	public async getMaxSupply(): Promise<EthBigNumber | null> {
		return Services.getInstance().getMaxSupply(this.nftCollectionContract!);
	}

	public async balanceOf(userAddress: string): Promise<EthBigNumber | null> {
		return Services.getInstance().balanceOf(this.nftCollectionContract!, userAddress);
	}

	public async getPublicStatus(): Promise<boolean | null> {
		return Services.getInstance().getPublicStatus(this.nftCollectionContract!);
	}

	public async getPrivateStatus(): Promise<boolean | null> {
		return Services.getInstance().getPrivateStatus(this.nftCollectionContract!);
	}

	public async getPrivateHardcap(): Promise<EthBigNumber | null> {
		return Services.getInstance().getPrivateHardcap(this.nftCollectionContract!);
	}

	public async getPublicMaxMintPerTx(): Promise<EthBigNumber | null> {
		return Services.getInstance().getPublicMaxMintPerTx(this.nftCollectionContract!);
	}

	public async getAirdropTokens(): Promise<EthBigNumber | null> {
		return Services.getInstance().getAirdropTokens(this.nftCollectionContract!);
	}

	public async getPrivateMaxMintPerTx(): Promise<EthBigNumber | null> {
		return Services.getInstance().getPrivateMaxMintPerTx(this.nftCollectionContract!);
	}

	public async getPrivateTokensSold(): Promise<EthBigNumber | null> {
		return Services.getInstance().getPrivateTokensSold(this.nftCollectionContract!);
	}

	public async getPublicTokensSold(): Promise<EthBigNumber | null> {
		return Services.getInstance().getPublicTokensSold(this.nftCollectionContract!);
	}

	public async getPrivatePrice(): Promise<EthBigNumber | null> {
		return Services.getInstance().getPrivatePrice(this.nftCollectionContract!);
	}

	public async getPublicPrice(): Promise<EthBigNumber | null> {
		return Services.getInstance().getPublicPrice(this.nftCollectionContract!);
	}

	public async getUserPrivateSaleMintedAmount(userAddress: string): Promise<EthBigNumber | null> {
		return Services.getInstance().getUserPrivateSaleMintedAmount(this.nftCollectionContract!, userAddress);
	}

	public async mintPublicSale(amount: EthBigNumber, price: EthBigNumber) {
		return Services.getInstance().mintPublicSale(this.nftCollectionContract!, amount, price);
	}
	

	public async isWhitelisted(userAddress: string) {
		return await Webservices.Whitelist.isWhitelisted({
			userAddress: userAddress,
		});
	}

	public async mintPrivateSale(amount: EthBigNumber, price: EthBigNumber) {
		const mintEntity = await Webservices.Whitelist.signMessage({
			address: Wallet.getInstance().walletData.userAddress!,
		});
		const { signature } = mintEntity;
		return Services.getInstance().mintPrivateSale(this.nftCollectionContract!, signature, amount, price);
	}
}

