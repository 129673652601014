import React from "react";
import classes from "./classes.module.scss";
import logo from "../../../assets/images/pages/home/logo.png";

import Instagram from "../../../assets/images/pages/home/icon-instagram.svg";
import Twitter from "../../../assets/images/pages/home/icon-twitter.svg";
import Discord from "../../../assets/images/pages/home/icon-discord.svg";
import Website from "../../../assets/images/pages/home/icon-website.svg";

import { INSTAGRAM_LINK, TWITTER_LINK, DISCORD_LINK, WEBSITE_LINK } from "assets/links/links";

export default class Footer extends React.Component {
	public override render(): JSX.Element | null {
		return (
			<footer className={classes["root"]}>
				<div className={classes["root-container"]}>
					<img alt="logo" src={logo} className={classes["logo"]} />
					<div className={classes["root-text"]}>BY THE PLAYER, FOR THE COMMUNITY!</div>
					<div className={classes["root-right"]}>
						<a href={INSTAGRAM_LINK} target="_blank" rel="noreferrer">
								<img alt="social" src={Instagram} />
							</a>
							<a href={TWITTER_LINK} target="_blank" rel="noreferrer">
								<img alt="social" src={Twitter} />
							</a>
							<a href={DISCORD_LINK} target="_blank" rel="noreferrer">
								<img alt="social" src={Discord} />
							</a>
							<a href={WEBSITE_LINK} target="_blank" rel="noreferrer">
								<img alt="website" src={Website} />
							</a>
					</div>
				</div>
			</footer>
		);
	}
}
