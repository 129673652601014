import { ISignature } from "Entities/Mint/SignatureEntity";
import EthBigNumber from "Services/Wallet/EthBigNumber";

import NftCollectionContract from './Classes/NftCollectionContract';

export default class Services {
	private static ctx: Services;

	private constructor() {
		Services.ctx = this;
	}

	public static getInstance(newInstance: boolean = false) {
		if (!Services.ctx || newInstance) new this();
		return Services.ctx;
	}

	public async getMaxSupply(contract: NftCollectionContract): Promise<EthBigNumber | null> {
		return contract.getMaxSupply();
	}

	public async balanceOf(contract: NftCollectionContract, userAddress: string): Promise<EthBigNumber | null> {
		return contract.balanceOf(userAddress);
	}

	public async getPublicStatus(contract: NftCollectionContract): Promise<boolean | null> {
		return contract.getPublicStatus();
	}

	public async getPrivateStatus(contract: NftCollectionContract): Promise<boolean | null> {
		return contract.getPrivateStatus();
	}

	public async getPrivateHardcap(contract: NftCollectionContract): Promise<EthBigNumber | null> {
		return contract.getPrivateHardcap();
	}

	public async getPublicMaxMintPerTx(contract: NftCollectionContract): Promise<EthBigNumber | null> {
		return contract.getPublicMaxMintPerTx();
	}

	public async getAirdropTokens(contract: NftCollectionContract): Promise<EthBigNumber | null> {
		return contract.getAirdropTokens();
	}

	public async getPrivateMaxMintPerTx(contract: NftCollectionContract): Promise<EthBigNumber | null> {
		return contract.getPrivateMaxMintPerTx();
	}

	public async getPrivateTokensSold(contract: NftCollectionContract): Promise<EthBigNumber | null> {
		return contract.getPrivateTokensSold();
	}

	public async getPublicTokensSold(contract: NftCollectionContract): Promise<EthBigNumber | null> {
		return contract.getPublicTokensSold();
	}

	public async getPrivatePrice(contract: NftCollectionContract): Promise<EthBigNumber | null> {
		return contract.getPrivatePrice();
	}

	public async getPublicPrice(contract: NftCollectionContract): Promise<EthBigNumber | null> {
		return contract.getPublicPrice();
	}

	public async getUserPrivateSaleMintedAmount(
		contract: NftCollectionContract,
		userAddress: string,
	): Promise<EthBigNumber | null> {
		return contract.getUserPrivateSaleMintedAmount(userAddress);
	}

	public async mintPublicSale(
		contract: NftCollectionContract,
		amount: EthBigNumber,
		price: EthBigNumber,
	) {
		return contract.mintPublicSale(amount, price);
	}

	public async mintPrivateSale(
		contract: NftCollectionContract,
		signature: ISignature,
		amount: EthBigNumber,
		price: EthBigNumber,
	) {
		return await contract.mintPrivateSale(signature, amount, price);
	}
}

