import Button from "Components/Elements/Button";
import React from "react";
import Wallet, { IWallet } from "Stores/Wallet";
import AddressShortElement from "Components/Elements/AddressShort";
import disconnect from "../../../assets/images/icons/disconnect.svg";
import classes from "./classes.module.scss";

type IProps = {
	onClick?: () => void;
};
type IState = {
	userAddress: string | null;
};

export default class ConnectWallet extends React.Component<IProps, IState> {
	private removeOnChange = () => {};

	constructor(props: IProps) {
		super(props);
		this.state = {
			userAddress: Wallet.getInstance().walletData?.userAddress ?? null,
		};
	}

	public render(): JSX.Element {
		const isConnected = !!this.state.userAddress;
		return (
			<div className={classes["root"]}>
				{!isConnected && (
					<Button variant="secondary" onClick={() => this.connect()}>
						CONNECT WALLET
					</Button>
				)}
				{isConnected && (
					<div>
						<AddressShortElement text={this.state.userAddress} />
						<img alt="icon" src={disconnect} onClick={() => this.disconnect()} />
					</div>
				)}
			</div>
		);
	}

	public componentDidMount() {
		this.removeOnChange = Wallet.getInstance().onChange((web3Event: IWallet | null) => this.onChange(web3Event));
	}

	public componentWillUnmount() {
		this.removeOnChange();
	}

	private async connect(): Promise<void> {
		Wallet.getInstance().connect();
	}

	private async disconnect() {
		Wallet.getInstance().disconnect();
	}

	private onChange(walletData: IWallet | null) {
		this.setState({
			userAddress: walletData?.userAddress ?? null,
		});
	}
}
