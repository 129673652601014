import { BigNumber } from "ethers";
import React from "react";

import { COINZOOM_LINK, DISCORD_LINK, INSTAGRAM_LINK, TWITTER_LINK, WEBSITE_LINK } from "assets/links/links";
import MintConnectWallet from "Components/Materials/MintConnectWallet";
import PlusMinusButton from "Components/Materials/PlusMinusButton";
import DefaultTemplate from "Components/PageTemplates/DefaultTemplate";
import Config from "Configs";
import EthBigNumber from "Services/Wallet/EthBigNumber";
import Contract from "Stores/Contract";
import Wallet from "Stores/Wallet";

import Coinzoom from "../../../assets/images/pages/home/coinzoom.svg";
import Discord from "../../../assets/images/pages/home/discord.svg";
import Instagram from "../../../assets/images/pages/home/instagram.svg";
import Pow from "../../../assets/images/pages/home/pow.svg";
import Twitter from "../../../assets/images/pages/home/twitter.svg";
import Website from "../../../assets/images/pages/home/website-button.svg";
import classes from "./classes.module.scss";
import MintPrice from "./MintPrice";

enum Phase {
	PRE_SALE = "Pre-Sale",
	PUBLIC_SALE = "Public Sale",
	ENDED = "ENDED",
}
type IProps = {};

type IState = {
	phase: Phase;
	maxSupply: number | null;
	maxMintable: number | null;
	hardcapPerUser: number | null;
	userMintedAmount: number | null;
	remainingPass: number | null;
	price: EthBigNumber | null;
	totalPrice: EthBigNumber | null;
	userAddress: string | null;
	amount: number | null;
	isWhitelisted: boolean;
	publicActive: boolean | null;
	privateActive: boolean | null;
	loading: boolean;
	network: string | null;
	userBalance: EthBigNumber | null;
	isButtonLoading: boolean;
};

type IMintData = {
	phase: Phase;
	remainingPass: string;
	mintDate: string;
	mintPrice: string;
	amountPerUser: string;
	isWhitelisted: boolean;
};
export default class Mint extends React.Component<IProps, IState> {
	private removeContractOnChange = () => {};
	constructor(props: IProps) {
		super(props);
		this.state = {
			isButtonLoading: true,
			loading: true,
			phase: Phase.PUBLIC_SALE,
			maxSupply: null,
			maxMintable: null,
			remainingPass: null,
			price: null,
			hardcapPerUser: null,
			userMintedAmount: null,
			totalPrice: null,
			amount: 1,
			userAddress: Wallet.getInstance().walletData?.userAddress ?? null,
			isWhitelisted: true,
			publicActive: false,
			privateActive: false,
			network: null,
			userBalance: null,
		};
		this.onContractChange();
	}

	public render(): JSX.Element {
		const privateMintDate = Config.getInstance().get().contracts.nftCollection.privateMintDate;
		const publicMintDate = Config.getInstance().get().contracts.nftCollection.publicMintDate;
		const mintDate = this.state.phase === Phase.PRE_SALE ? privateMintDate : publicMintDate;

		const mintData: IMintData = {
			phase: this.state.phase,
			remainingPass: this.state.remainingPass?.toString() ?? "... PASS",
			mintDate,
			mintPrice: this.state.price ? this.state.price.formatUnits() : "...",
			amountPerUser:
				(this.state.userMintedAmount ?? "...") + "/" + (this.state.hardcapPerUser ?? "...") + " NFTs",
			isWhitelisted: this.state.isWhitelisted,
		};

		return (
			<DefaultTemplate title="Utah Jazz - Mint">
				<div className={classes["header"]}>
					<div className={classes["minter-flex"]}>
						<div className={classes["minter-container"]}>
							<div className={classes["minter"]}>
								<h1>Jazz Heroes</h1>
								{/* <div className={classes["minter-coming"]}>
								Public mint coming soon!
							</div> */}
								<div className={classes["minter-infos"]}>
									<div className={classes["minter-cell"]}>
										<div className={classes["minter-cell-title"]}>PHASE</div>
										<div className={classes["minter-cell-value"]}>{mintData.phase}</div>
									</div>

									<div className={classes["minter-cell"]}>
										<div className={classes["minter-cell-title"]}>DATE MINT</div>
										<div className={classes["minter-cell-value"]}>{mintData.mintDate}</div>
									</div>

									<div className={classes["minter-cell"]}>
										<div className={classes["minter-cell-title"]}>MAX MINT/USER</div>
										<div className={classes["minter-cell-value"]}>No limit</div>
									</div>

									<div className={classes["minter-cell"]}>
										<div className={classes["minter-cell-title"]}>NFT OWNED</div>
										<div className={classes["minter-cell-value"]}>
											{this.state.userBalance?.toNumber() ?? "..."}
										</div>
									</div>

									<div className={classes["minter-cell"]}>
										<div className={classes["minter-cell-title"]}>MINT PRICE</div>
										<div className={classes["minter-cell-value"]}>Ξ {mintData.mintPrice}</div>
									</div>

									<div className={classes["minter-cell"]}>
										<div className={classes["minter-cell-title"]}>SUPPLY REMAINING</div>
										<div className={classes["minter-cell-value"]}>
											{this.state.remainingPass ?? "..."}/{this.state.maxSupply ?? "..."}
										</div>
									</div>
								</div>
								<div className={classes["mint-amount"]}>
									<PlusMinusButton
										max={this.state.maxMintable ?? 0}
										onChange={(value) => {
											this.updateTotalPrice(value);
										}}
									/>
									<div className={classes["border"]} />
									<MintPrice price={this.state.totalPrice?.formatUnits() ?? "..."} crypto="Ξ" />
								</div>
								<div className={classes["mint-button"]}>
									<MintConnectWallet
										isButtonLoading={this.state.isButtonLoading}
										onClick={async () => {
											let tx;
											this.setState({ isButtonLoading: true });
											if (mintData.phase === Phase.PRE_SALE) {
												tx = await Contract.getInstance().mintPrivateSale(
													new EthBigNumber(BigNumber.from(this.state.amount)),
													this.state.price!,
												);
											} else {
												tx = await Contract.getInstance().mintPublicSale(
													new EthBigNumber(BigNumber.from(this.state.amount)),
													this.state.price!,
												);
											}

											if (!tx) {
												this.setState({ isButtonLoading: false });
												return;
											}
											await tx.wait();
											this.updateState();
										}}
										disabled={!mintData.isWhitelisted || this.state.maxMintable === 0}
									/>
								</div>
							</div>
							<div className={classes["minter-below"]}>
								<img alt="coinzoom" src={Coinzoom} />
								<div>
									<p>Don't have a WALLET ?</p>
									Create a CoinZoom account and mint your Jazz Heroes NFT with Credit/debit card ACH,
									Wires, Cash or Crypto.
									<br />
									<a href={COINZOOM_LINK} target="_blank" rel="noreferrer">
										Go mint
									</a>
								</div>
							</div>
						</div>
					</div>
					<div className={classes["minter-bg"]}>
						<video key={"home"} autoPlay loop muted playsInline preload="auto">
							<source src={"/reveal.mp4"} type="video/mp4" />
							Your browser does not support the video tag.
						</video>
					</div>
				</div>

				<div className={classes["collection-info"]}>
					<div className={classes["collection-info-container"]}>
						<h2>Players generated collection</h2>
						<p>
							All proceeds from the mint are going to a charitable project in Utah through the Rudy's Kids
							Foundation
						</p>
						{/* <div className={classes["collection-info-grid"]}>
							<div>
								<p>About us</p>
								<div>
									The "Jazz Heroes" collection is the first player-initiated NFT collection that
									depicts Utah Jazz players as superheroes. The players featured in this collection
									aim to be the first team to create a unique NFT collection and build a passionate
									community with amazing art in the Web3 space.
									<br />
									<br /> Our project starts with an affordable mint at a very low price to expand our
									online community by the end of June. All NFT holders will automatically receive a
									20% discount on the online JazzShop. All holders will also be eligible to win
									exclusive collectibles and future experiences with Rudy Gobert through raffles.
								</div>
							</div>
							<div>
								<p>Values</p>
								<div>
									All proceeds from the collection will go to the Rudy's Kids Foundation. The mission
									of Rudy's Kids Foundation is to help improve the lives of disadvantaged children and
									youth around the world, including in Utah. These funds will be used to provide
									Rudy's Kids with access to opportunities and resources in the health, education, and
									recreational areas.
									<br />
									<br /> In regard to the values we stand upon, we want to thank everyone who supports
									this exciting new adventure for joining our new online global community.
									<br />
									<br /> Together we can change the world.
								</div>
							</div>
						</div> */}
						<img alt="pow" src={Pow} className={classes["collection-info-pow"]} />
					</div>
				</div>

				{/* <div className={classes["collection-samples"]}>
					<h2>THE COLLECTION</h2>
					<div className={classes["collection-samples-grid"]}>
						<img alt="sample" src={Trent} />
						<img alt="sample" src={Mike} />
						<img alt="sample" src={Don} />
						<img alt="sample" src={Ocho} />
						<img alt="sample" src={Rudy} />
						<img alt="sample" src={Udoka} />
						<img alt="sample" src={Royce} />
						<img alt="sample" src={Jared} />
					</div>
				</div>

				<div className={classes["advantages"]}>
					<div className={classes["advantages-container"]}>
						<h2>The Advantages</h2>
						<div className={classes["advantages-grid"]}>
							<div className={classes["advantages-cell"]}>
								<h3>Discounts</h3>
								<p>All holders will benefit from a 20% online discount at the JazzShop.</p>
							</div>
							<div className={classes["advantages-cell"]}>
								<h3>Raffles</h3>
								<p>
									All holders can access raffles to win exclusive collectibles and unique experiences.
								</p>
							</div>
						</div>
						<div className={classes["advantages-more"]}>
							&<br />
							Special surprises...
						</div>
						<div className={classes["advantages-best"]}>
							The best is yet to come…stay tuned for further announcements
						</div>

						<img alt="lightning" className={classes["lightning-br"]} src={LightningBr} />
						<img alt="lightning" className={classes["lightning-tr"]} src={LightningTr} />
						<img alt="lightning" className={classes["lightning-mr"]} src={LightningMr} />
					</div>
				</div> 
				
				<div className={classes["roadmap"]}>
					<h2>Roadmap</h2>
					<div />
				</div> */}

				<div className={classes["socials"]}>
					<div className={classes["socials-container"]}>
						<h2>Socials</h2>
						<div className={classes["socials-grid"]}>
							<a href={INSTAGRAM_LINK} target="_blank" rel="noreferrer">
								<img alt="social" src={Instagram} />
							</a>
							<a href={TWITTER_LINK} target="_blank" rel="noreferrer">
								<img alt="social" src={Twitter} />
							</a>
							<a href={DISCORD_LINK} target="_blank" rel="noreferrer">
								<img alt="social" src={Discord} />
							</a>
							<a href={WEBSITE_LINK} target="_blank" rel="noreferrer">
								<img alt="social" src={Website} />
							</a>
						</div>
					</div>
				</div>

				{/* <div className={classes["partners"]}>
					<div className={classes["partners-container"]}>
						<h2>Partners & Advisors</h2>
						<div className={classes["partners-grid"]}>
							<div className={classes["partners-cell"]}>
								<img alt="social" src={PartnerRudy} className={classes["partners-logo"]} />
								<h3>Rudy Gobert</h3>
								<p>Founder & Creative Director</p>
								<a href={INSTAGRAM_RUDY_LINK} target="_blank" rel="noreferrer">
									<img alt="social" src={Instagram} className={classes["partners-btn"]} />
								</a>
							</div>
							<div className={classes["partners-cell"]}>
								<img alt="social" src={PartnerTact} className={classes["partners-logo"]} />
								<h3>Tact Studio</h3>
								<p>Artist</p>
								<a href={TACT_STUDIO_LINK} target="_blank" rel="noreferrer">
									<img alt="social" src={Website} className={classes["partners-btn"]} />
								</a>
							</div>
							<div className={classes["partners-cell"]}>
								<img alt="social" src={PartnerSmartchain} className={classes["partners-logo"]} />
								<h3>Smart-Chain</h3>
								<p>Blockchain & Smart-contract developers</p>
								<a href={SMART_CHAIN_LINK} target="_blank" rel="noreferrer">
									<img alt="social" src={Website} className={classes["partners-btn"]} />
								</a>
							</div>
						</div>
					</div>
				</div>

				<FaqSection /> */}
			</DefaultTemplate>
		);
	}

	public async componentDidMount() {
		this.removeContractOnChange = Contract.getInstance().onChange(async () => this.onContractChange());
	}

	public componentWillUnmount() {
		this.removeContractOnChange();
	}

	private onContractChange() {
		this.setState({
			userAddress: Wallet.getInstance().walletData?.userAddress ?? null,
		});
		this.updateState();
	}

	private async updateState() {
		const publicActive = await Contract.getInstance().getPublicStatus();
		const privateActive = await Contract.getInstance().getPrivateStatus();
		const maxSupply = (await Contract.getInstance().getMaxSupply())?.toNumber() ?? 0;
		const userBalance = this.state.userAddress
			? await Contract.getInstance().balanceOf(this.state.userAddress!)
			: null;
		let price = null;
		let maxMintable = null;
		let hardcapPerUser = null;
		let phase: Phase = Phase.PUBLIC_SALE;
		let remainingPass = null;
		let userMintedAmount = null;
		let isWhitelisted = false;
		price = await Contract.getInstance().getPublicPrice();
		const privateTokensSold = (await Contract.getInstance().getPrivateTokensSold())?.toNumber();
		const publicTokensSold = (await Contract.getInstance().getPublicTokensSold())?.toNumber();
		const airdropTokens = (await Contract.getInstance().getAirdropTokens())?.toNumber();
		remainingPass = maxSupply! - (privateTokensSold! + publicTokensSold! + airdropTokens!);

		if (publicActive) {
			phase = Phase.PUBLIC_SALE;

			const publicMaxMintPerTx = (await Contract.getInstance().getPublicMaxMintPerTx())?.toNumber();

			if (maxSupply && publicMaxMintPerTx) {
				maxMintable = Math.min(publicMaxMintPerTx, maxSupply);
			}
			isWhitelisted = true;
		}

		if (privateActive) {
			price = await Contract.getInstance().getPrivatePrice();
			hardcapPerUser = (await Contract.getInstance().getPrivateHardcap())?.toNumber() ?? 0;
			if (!!this.state.userAddress) {
				userMintedAmount =
					(await Contract.getInstance().getUserPrivateSaleMintedAmount(this.state.userAddress))?.toNumber() ??
					null;
				const privateMaxMintPerTx = (await Contract.getInstance().getPrivateMaxMintPerTx())?.toNumber();
				const userMintable = hardcapPerUser - userMintedAmount!;
				maxMintable = Math.min(privateMaxMintPerTx!, maxSupply!, userMintable);
				isWhitelisted = true;
			}
		}

		this.setState({
			phase,
			price,
			maxMintable,
			hardcapPerUser,
			remainingPass,
			userMintedAmount,
			totalPrice: price,
			isWhitelisted,
			publicActive,
			privateActive,
			loading: false,
			userBalance,
			maxSupply,
			isButtonLoading: false,
		});
	}

	private updateTotalPrice(value: number) {
		this.setState({
			totalPrice: this.state.price?.mul(EthBigNumber.from(value.toString(), 0)) ?? null,
			amount: value,
		});
	}
}
